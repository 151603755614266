enum DataIdPage {
  INDEX_PAGE = 'index-page',
  INDEX_PAGE_MOBILE = 'index-page-mobile',
  ID_PAGE = 'id-page',
  ID_PAGE_MOBILE = 'id-page-mobile',
}

enum DataIdElement {
  HEADING = 'heading',
  HEADING_MOBILE = 'heading-mobile',
  TEXT = 'text',
  TEXT_MOBILE = 'text-mobile',
  SHIPMENT_NUMBER_INPUT = 'shipment-number-input',
  SHIPMENT_NUMBER_SUBMIT_BTN = 'shipment-number-submit-btn',
  STATE_PANEL = 'state-panel',
  TAB = 'tab',
  TAB_CONTENT = 'tab-content',
  CUSTOMER_SERVICE_LINK = 'customer-service-link',
  HAMBURGER_BTN = 'hamburger-btn',
  CLOSE_BTN = 'close-btn',
  NAVBAR_CONTENT = 'navbar-content',
  TRACKING_MENU_BTN = 'tracking-menu-btn',
}

enum DataIdAtom {
  ATOM_INPUT = 'atom-input',
}

enum DataIdMolecule {
  MOLECULE_ALERT_BADGE = 'molecule-alert-badge',
}

enum TestPacketTypes {
  WAITING_FOR_DELIVERY_WITH_COD = 'WAITING_FOR_DELIVERY_WITH_COD',
  WAITING_FOR_DELIVERY_WITHOUT_COD = 'WAITING_FOR_DELIVERY_WITHOUT_COD',
  ON_THE_WAY_WITH_COD = 'ON_THE_WAY_WITH_COD',
  ON_THE_WAY_WITHOUT_COD = 'ON_THE_WAY_WITHOUT_COD',
  READY_FOR_PICKUP_WITH_COD = 'READY_FOR_PICKUP_WITH_COD',
  READY_FOR_PICKUP_WITHOUT_COD = 'READY_FOR_PICKUP_WITHOUT_COD',
  ISSUED_AND_ACCOUNTED_WITH_COD = 'ISSUED_AND_ACCOUNTED_WITH_COD',
  ISSUED_AND_ACCOUNTED_WITHOUT_COD = 'ISSUED_AND_ACCOUNTED_WITHOUT_COD',
}

export { DataIdPage, DataIdElement, DataIdAtom, DataIdMolecule, TestPacketTypes }
